import React from 'react';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { Link } from 'gatsby-theme-material-ui';
import ProTip from '../components/ProTip';
import Copyright from '../components/Copyright';
import Header from '../components/header';
import Content from '../components/Content';
import PageIntro from '../components/pageIntro';
import PageTitle from '../components/pageTitle';
import Card1 from '../components/Card1';
import Card2 from '../components/Card2';




export default function About() {
  return (
    <Grid Container direction="column">
      <Grid item>
        <Header title="Expérience"/>
      </Grid>
      <Grid item>
      <Container maxWidth="sm">
      <Box my={4}>
      <PageTitle/>
      <PageIntro content="Mon expérience du développement de plateformes mobiles d'entreprise est ce que j'ai de plus précieux pour assurer le succès d'un projet informatique" />
      <Card1 title="Plateforme de crédit PWA Zypki" customer="Crédit Agricole Brie Picardie" description="C'est notamment grâce à ce projet que je connais les impacts d'un projet de cette ampleur (des dizaines de milliers d'utilisateurs), les contraintes d'un hébergement IT bancaire, les spécificités d'une PWA " moreLink="https://www.southside-interactive.com/zypki-pwa-credit-agricole/" />
<Card1 title="Application PWA tutoriel isolant" customer="Saint Gobain Isover" description="C'est notamment grâce à ce projet que j'ai bien en tête l'importance et la difficulté d'assimiler le métier des futurs utilisateurs, et l'efficacité d'un fonctionnement étape par étape malgré la grande difficulté à le faire accepter" moreLink="https://www.southside-interactive.com/application-tutorial-isover-climaver/" />
<Card2 title="Plateforme iPhone Android ambulatoire" customer="Hôpital Franco-britannique" description="C'est notamment grâce à ce projet que je mesure la complexité des situations opérationnelles vécues au quotidien et la rigueur du travail préalable à fournir pour arriver à une expérience numérique aussi fluide" moreLink="" />
<Card1 title="Application mobile déchets de chantier" customer="Vinci Liva " description="Depuis ce projet je garde toujours en tête l'importance de la mise en place hardware préalable à un déploiement logiciel, et la spécificité de chaque audience d'utilisateurs" moreLink="https://www.southside-interactive.com/liva-smartbac-application-offline-first-chantier/" />
<Card1 title="Plateforme de communication interne Marius" customer="Crédit Agricole Brie Picardie" description="J'ai désormais parfaitement en tête les nombreuses interactions que nécessite un projet d'ampleur dans une grande entreprise quand il s'étend dans la durée, et l'enjeu de pérennité malgré les départs de certains collaborateurs clé" moreLink="https://www.southside-interactive.com/marius-app-mobile-credit-agricole/" />
<Card2 title="Plateforme bancaire mutualiste Tookets" customer="Crédit Agricole " description="Je sais grâce à ce projet quelles sont les impacts d'un projet d'un outil gérant plus d'un millions de compte, et les étapes nécessaires à la manipulations de giga octets de données client" moreLink=" " />
<Card2 title="Plateforme mobile non-conformité en entrepôt" customer="Ceva Logistics" description="Ce projet m'a montré que les problématiques très métier nécessitent une communication très poussée et une confrontation très rapide avec les responsables métiers porteurs du projet" moreLink=" " />
<Card2 title="Application géolocalisation en arrière-plan" customer="Crédit Agricole Loire Haute Loire" description="Je connais désormais les spécificités liées à un projet impliquant de la géolocalisation en arrière plan, et les contraintes ergonomiques et techniques impliquées, sur iOS et sur Android" moreLink=" " />
<Card2 title="Plateforme PWA communication interne" customer="Crédit Agricole Franche Comté" description="J'ai pu constater combien les projets informatiques en entreprise dépassent la simple dimension technique, et impliquent une compréhension fine de ceux qui seront in fine les décideurs" moreLink=" " />
<Card2 title="Plateforme mobile relation clients unifiée" customer="Crédit Agriole Normandie Seine" description="Je connais désormais la dynamique projet d'un projet transformant dans une grande entreprise, avec les différents enjeux et les potentiels risques qui jalonnent un projet s'étalant sur plusieurs années" moreLink=" " />
<Card2 title="Application mobile enveloppes réalité augmentée" customer="Docapost" description="Mettre à profit la technologie de réalité augmentée a signifié identifier ce que cette technologie apportait vraiment par rapport à un support plus classique sur papier ou sur écran, ce genre de questionnement étant toujours très riche dans tout projet informatique" moreLink=" " />
<Card2 title="Plateforme de mentoring" customer="Inovexus" description="Pour ce projet j'ai pu intégrer le besoin d'acquérir les utilisateurs pour que le service qui a un intérêt réel ait réellement une chance d'être envisagé puis acheté par l'audience cible" moreLink=" " />
<Card1 title="Plateforme mobile réceptions litigieuses" customer="Scapnor" description="Cette application mobile adossée à un back office d'amdministration qui relie 3 entrepôts aux services de gestion de litiges intègre un mode hors ligne, si souvent nécessaire pour des outils de travail" moreLink="https://datapp.fr/site/" />
<Card1 title="Site internet médical" customer="Médecine Manuelle " description="J'ai structuré le projet de façon à pouvoir faire la totalité du site en 24h, de façon à faire émerger d'entrée les prises de conscience qui ne peuvent surgir qu'en fin de projet" moreLink="http://medecine-manuelle-paris.fr/" />
<Card1 title="Application web coaching " customer="Saint Gobain" description="Grâce à ce projet, je comprends bien les dynamiques à l'œuvre dans des projets transverses à plusieurs pays, tant sur les niveaux de décisions que sur les enjeux de déploiements propres à chaque pays" moreLink="https://www.salescoaching-app.com/#/login" />
<Card1 title="Application web coaching " customer="Saint Gobain Placo" description="Ce projet m'a permis de vivre la transposition d'un outil existant dans un autre conteste, le juste équilibre entre ce qu'il y a à garder et ce qu'il faut s'interdire" moreLink="https://www.salescoaching-app.com/placo/#/login" />
<Card1 title="Site internet présentation tbomp" customer="Site personnel" description="Ce premier site de présentation illustrait mon rôle à la frontière entre technique et métier, qui s'est élargi depuis à toutes les dimensions constitutives d'un projet numérique" moreLink="http://tbomp.com/version2014/" />
<Card2 title="Application commercial marketing terrain" customer="Saint Gobain Glass" description="Pour ce projet, j'avais parfaitement en tête l'enjeu d'onboarging et de fluiditité nécessaire à une quelconque utilisation par l'audience cible, en particulier l'enjeu d'utilisation hors ligne" moreLink=" " />
<Card1 title="Site internet édition junkago" customer="Site personnel" description="Ce site qui sert de porte d'entrée vers d'autres support avait été une première concrétisation de mon besoin de relier ce qui existe, réflexe important malgré la tentative de faire table rase du passé lors des fréquents boulversements technologiques" moreLink="http://junkago.fr/" />
<Card2 title="Plateforme mobile coaching" customer="Total" description="Ce projet m'a permis de valider ma compréhension du déploiement d'un outil nouveau et en gestation ayant vocation à être utilisé dans plusieurs dizaines de pays" moreLink=" " />
<Card2 title="Application web dérogation de taux" customer="Crédit Agricole Centre Est" description="Ce projet mélangeait une forte compréhension métier et une problématique d'interfaçage aux SI pour laquelle nous avions tous conscience de l'importance d'architecturer le plus clairement possible la solution envisagée" moreLink=" " />
<Card1 title="Plateforme mobile capteurs IOT" customer="Urbasense" description="Au cours de ce projet j'ai été confronté au besoin de trouver un juste équilibre en profondeur de fonctionnalités et facilité d'accès, intégrant la spécificité d'une audience en évolution permanente" moreLink="https://www.southside-interactive.com/urbasense-architecture-developpement-mobile/" />
<Card2 title="Plateforme PWA formulaire métier" customer="Alvadiem" description="Ce projet m'a permis d'appronfondir la problématique de l'authenficiation d'utilisateurs peu engagés, pour faire face au besoin de convaincre progressivement l'audience cible" moreLink=" " />
<Card2 title="Plateforme web parrainage" customer="Crédit Agricole des Savoies" description="La spécificité de ce projet résidait dans la clarté et la facilité qu'il fallait suggérer, puis permettre. La remise en contexte et l'accès au service étant primordiaux." moreLink=" " />
<Card1 title="Plateforme mobile outils entrepôts" customer="Sofrilog" description="Le déploiement sur 5 sites à travers la France a mené à des discussions avec les interlocuteurs nationaux, et m'ont permis de bien négocier l'avancée progressive, presque paradoxale, entre local et national" moreLink="https://datapp.fr/site/" />
<Card2 title="Plateforme commande à emporter supermarché" customer="Cora" description="Ce projet illustre ma conviction que dans un déploiement à grande échelle, c'est la confrontation immédiate et grandeur nature sur le terrain qui permet de façonner la roadmap" moreLink=" " />
<Card2 title="Application justificatif domicile simplifié" customer="France Connect" description="J'ai pu comprendre en me plongeant dans ce sujet les enjeux de fédération d'identité, au niveau technique et au niveau de l'expérience qu'elle permet à l'utilisateur" moreLink=" " />
<Card2 title="Application mobile géolocalisée" customer="Crédit Agricole Ille et Vilaine" description="Ce projet fut l'occasion de creuser toutes les options de géolocalisation indoor, et fut un exemple parmi d'autres que les bonnes solutions ne sont pas toujours les plus technologiques" moreLink=" " />
<Card1 title="Site internet affichage peint à la main" customer="Monsieur Peinture" description="J'ai vécu dans ce projet l'importance de partir du contenu, de l'intérêt de viser une version défintive simple pour aller vite et être ensuite dans une démarche d'amélioration" moreLink="https://p-a-l-m.com/" />
<Card2 title="Plateforme suivi de tâches" customer="Sofema" description="J'ai pu capitaliser sur les outils Microsoft déjà utilisés pour améliorer les processus aujourd'hui en place en imaginant leur prolongement sur le terrain " moreLink=" " />
<Card2 title="Plateforme formulaires financements " customer="Telios" description="Dans ce projet, c'est la variété des interlocuteurs et des rôles qui a constitué la difficulté majeure, en interaction directe avec les processus métier spécifique à l'inversissement à intégrer " moreLink=" " />
<Card1 title="Platforme mobile colis BLE" customer="Boks" description="La logique produit malgré l'innovation permanente nécessaire a mené à se focaliser sur un cycle complet satisfaisant et fluide, bien en amont et bien en aval de l'application, qui reste la meilleure façon de faire selon moi" moreLink="https://www.southside-interactive.com/boks-plateforme-mobile-ble/" />
<Card2 title="Application mobile accès à l'investissement" customer="Crédit Agricole" description="Comme c'est souvent le cas, ce projet était avant tout un sujet de visibilité : désamorcer les attentes fonctionnelles a permis de se focaliser sur ce qui importait vraiment " moreLink=" " />
<Card2 title="Plateforme bancaire interchange" customer="Crédit Agricole des Savoies" description="C'est via des projets comme celui-ci je sais que dans un contexte de déploiement particulier, il faut un travail très poussé avec toutes les parties prenantes qui seront amenées à utiliser l'outil conçu." moreLink=" " />
<Card2 title="Application vendeur réalité augmentée" customer="Leroy Merlin" description="La réalité augmentée utilisée dans un espace de travail pousse plus que tout autre technologie à s'imaginer en situation, ce qui est toujours tellement utile " moreLink=" " />
<Card1 title="Site internet documentaire" customer="Société des études rénaniennes" description="La grande quantité de contenu attendue sur ce projet fut l'occasion de bien délimiter le moment où le relai doit être transmis à nouveau, et se faire en dehors de la phase de construction du site, dans un quotidien de mise à jour" moreLink="http://ernest-renan.fr/" />
<Card2 title="Plateforme facilitation accès au SI" customer="Crédit Agricole Brie Picardie" description="Ce projet a impliqué une très grande proximité avec les interlocuteurs SI, pour déterminer précisément les éléments structurant de l'accès aux données, et arbitrer au mieux" moreLink=" " />
<Card2 title="Plateforme efficacité commerciale" customer="LOCAM" description="Ce projet a necessité de bien garder en tête les enjeux à l'origine du projet, enjeux commerciaux porté par une direction commerciale, et de bâtir la réponse en ce sens." moreLink=" " />
<Card2 title="Application web aquisition immobilier" customer="Crédit Agricole Centre France" description="C'est notamment sur ce projet que j'ai pu constater l'importance pour un projet d'innovation de s'appuyer sur des premiers éléments concrets pour convaincre à tous les niveaux de décision" moreLink=" " />
<Card2 title="Application mobile coaching" customer="Uni editions" description="Ce projet m'a permis de creuser les possibilités techniques offertes par les OS mobiles en ce qui concerne les applications en background, et de me faire un avis sur la meilleure façon de l'exploiter" moreLink=" " />
<Card2 title="Application mobile formation" customer="Crédit Agricole Nord de France" description="Ce projet était porté par des équipes techniques, et fut l'occasion de travailler de façon plus directe et plus simple, en parlant d'une seule voix et en capitalisant sur des outils communs" moreLink=" " />
<Card2 title="Plateforme PWA réseau interne" customer="CAMCA" description="Pour ce projet j'ai du me focaliser sur les quotidiens d'utilisations, l'occasion de maximiser l'impact en partant de gestes attendus, et en imaginant les fonctionnalités dans cette perspective" moreLink=" " />
<Card2 title="Plateforme PWA communication métier" customer="Crédit Agriocole Languedoc" description="C'est l'accès à l'application dans le quotidien des utilisateurs qui était clé ici ; je milite toujours pour partir de l'environnement d'utilisation, valider la réalité de l'utilisation avant d'approfondir les fonctionnalités" moreLink=" " />
<Card2 title="Site internet investissement immobilier" customer="Crédit et Finance" description="Ce site faisant parti d'un dispositif en ligne élargi, il fallait bien arbitrer pour s'insérer au mieux dans l'existant, j'ai pu constater qu'une des clés d'un projet réussi est de bien choisir ses combats " moreLink=" " />
<Card2 title="Application iPhone et Android réseau mode" customer="Fittinpicks" description="La grande complexité de ce projet de recommandation d'habits basés sur des mesures m'a montré l'importance de se focaliser sur le contenu, d'anticiper un déroulé complet attendu, pour pouvoir en déduire l'expérience à développer" moreLink=" " />
<Card2 title="Pot de fleur connecté WIFI" customer="MEG" description="Un projet hardware implique des interactions nombreuses et complexes ; j'ai mis en place une méthodologie de recette qui témoigne de l'exigence qu'implique la réalisation d'un outil solide" moreLink=" " />
<Card2 title="Site internet solutions RH" customer="AMMSRH" description="Comme souvent, c'est l'immersion dans le métier et les utilisations attendues du site qui ont permis d'identifier les fonctionnalités à mettre en place et leur forme" moreLink=" " />
<Card2 title="UX gestion des contrats perso" customer="Axa Groupe Solutions" description="L'intégration d'une équipe produit chez le client m'a permis de comprendre les avantages et les inconvénients des environnements de projet particulièrement structurés" moreLink=" " />
<Card2 title="Site internet juridique Brésil" customer="BDS" description="Ce projet fut l'occasion d'un immense travail de documentation préalable qui aura permis de partir d'un contenu définitif, et d'un objectif clairement traduit en fonctionnalités précises" moreLink=" " />
<Card2 title="Site internet comptabilité" customer="BM Fiduciaire" description="Le parcours utilisateur à imaginer fut l'une des premières fois ou j'ai pu multiplier les scénarios d'utilisations et en déduire les composants à intégrer, un réflexe devenu tellement important depuis" moreLink=" " />
<Card2 title="Plateforme aquisition visiteurs" customer="Carrefour contact marché" description="Dans un contexte de grande distribution, l'approche marketing opérationnelle, très orientée résultat, nous a permis de complètement façonner la réponse technique à l'objectif commercial" moreLink=" " />
<Card2 title="Application engagement collaborateurs" customer="Carrefour Supply Chain" description="Ce projet impliquait des dizaines d'entrepôts dans toute la France, et un enjeu d'engagment très fort : l'application fut parfaitement utilisée comme prétexte pour impliquer plus de 20 000 collaborateurs, sur 8 mois" moreLink=" " />
<Card2 title="Veste de moto connectée BLE" customer="Axa Innovation" description="De la définition d'un concept qui puisse se raccrocher à des enjeux de la marque, à la réalisation de circuits imprimés ou de découpe laser au FabLab, ce projet fur un bel exemple de POC réalisé et livré en 4 semaines " moreLink=" " />
<Card2 title="Miroir connecté BLE" customer="Sephora" description="Pour animer un grand magasin parisien, ce projet fut une occasion poussée de ce où j'ai le plus à apporter : imaginer et choisir l'expérience qui permettrait d'avoir le plus d'impact, en intégrant chaque fois les limites de la faisabilité" moreLink=" " />
<Card2 title="Site internet institutionnel" customer="Fadegest" description="Au cours de la réalisation de ce site, j'ai pu passer du temps sur des sujets de CSS et de Javascript, et constater la rapide obsolescence des logiques d'implémentation, et l'importance de s'appuyer sur des composants maintenus" moreLink=" " />
<Card2 title="Application web diagnostic digital" customer="HOP Partners" description="Ce projet adossés à des centaines de formulaires fut une mise en pratique de l'interfaçage de différents outils en Saas rassemblés sous une ombrelle applicative dédiée, et de l'efficacité qu'elle permet" moreLink=" " />
<Card2 title="Site e-commerce événementiel" customer="Jeroboam" description="Dans un contexte d'entreprise en constante évolution, ce projet a exigé une grande flexibilité au niveau projet et au niveau technique, dans la durée, sans jamais heurter l'exigence de résultat" moreLink=" " />
<Card2 title="UX et Design appli force de vente" customer="MMC " description="Cette plongée dans le monde de l'assurance, notamment dans ses modes de fonctionnements et ses exigences techniques, fut l'occasion de bien appréhender les spécificités de l'IT métier à ce niveau " moreLink=" " />
<Card2 title="Marketplace e-commerce mode" customer="Mysoukinthecity" description="Une marketplace est un éco-systéme en lui-même : ce projet fut l'occasion d'insister sur le besoin d'impliquer les parties prenantes de cet écosystème pour parvenir à un résultat fluide" moreLink=" " />
<Card2 title="Channel vocal IOT WIFI" customer="Smartmonument" description="Des difficultés techniques majeures ont focalisé notre travail sur le dev, mais nous avons su multiplier les mises en situation pour lever un à un les lièvres associés à une technologie naissante" moreLink=" " />
<Card2 title="Marketplace e-commerce bijoux" customer="Rue de Sévigné" description="Le lancement de cette marketplace m'a permis de vivre les déiférentes étapes de l'évolution d'un projet e-commerce, et les enjeux logistiques et d'acquisition qui y sont liés" moreLink=" " />
<Card2 title="Présence en ligne" customer="Swisslife" description="C'est notamment grâce à ce projet que j'ai pu constater l'importance de la présence en ligne dans sa globalité, et le besoin de multiplier les points d'entrée puis de bien les relier les uns avec les autres" moreLink=" " />
<Card2 title="Application mobile animation communauté" customer="Fédération Nationale du Crédit Agricole" description="Face à l'incertitude et l'ambition à l'origine de se projet, c'est la recherche de simplicité maximale qui nous a permis de d'aboutir à une solution satisfaisante, comme c'est souvent le cas" moreLink=" " />
<Card2 title="Site internet promotion" customer="SR Games" description="La définition de la cible réelle de ce site, et la teinte que les contenus devaient donc avoir, fut l'un des sujets vécus sur ce projet qui m'a le plus influencé par la suite" moreLink=" " />
<Card1 title="Site internet recrutement" customer="Stanwell" description="Le site lui-même a pu être réalisé efficacement mais c'est la transmission et son impact sur le site lui-même qui furent le plus important, pour que cet outil soit bel et bien utilisé" moreLink="http://www.stanwellrecrute.com/" />
<Card2 title="Application iOS Android BLE IOT" customer="iSet Watch" description="La complexité des fonctionnements métiers et la précision des intégrations visuelles ont nécessité un suivi très précis et transparent, dont j'ai pris l'habitude et vois chaque jour les bienfaits" moreLink=" " />
<Card2 title="Application web édition supports chartés" customer="SNCF Editorium" description="L'enjeu d'utilisation paru une audience évoluant en permanence a pris le pas, au fil des années, sur les enjeux purement fonctionnels : à l'image de tous les outils qui deviennent matures" moreLink=" " />
<Card2 title="Application iOS Android événementielle" customer="Synerpa" description="La dimension événementielle de cet projet a impliqué des délais de livraisons très précis, et fut l'occasion d'une gestion au plus près des processus et des délais de publication des applications sur les store Apple et Android" moreLink=" " />
<Card2 title="Application mobile diagnostic médical" customer="Medecine des voyages" description="Pour faire face à la complexité technique liée aux nombreux traitements à effectuer, des arbitrages marqués ont du être faits entre les options techniques et leurs conséquences sur l'utilisation de l'app. Comme toujours !" moreLink=" " />
<Card2 title="Application web optimisation SAV" customer="Crédit Agricole Toulouse 31" description="Dans ce contexte très terre-à-terre de call center, c'est la variété et le nombre des potentiels utilisateurs qui était l'enjeu majeur ; pour y faire face, je sais qu'il faut multiplier les mises en situations et les observations" moreLink=" " />
<Card2 title="Analyse optimisation impact  " customer="Publicis ETO" description="Le rôle d'analyste que j'ai eu sur ce projet s'appuyait sur ma connaissance approfondie de sujets applicatifs variés, et m'a fait prendre conscience davantage encore du niveau de compréhension propre à chaque interlocuteur" moreLink=" " />
<Card1 title="Site internet réseau hopitaux" customer="COSEM" description="Les nombreux sites et leurs fonctionnalités parfois spécifiques, parfois communes, m'ont poussé à intégrer dans les développements certains points de vue stratégiques attendus mais non formulés, ce que je fais de plus en plus" moreLink="http://www.cosem.fr/" />
<Card2 title="Application mobile capteur IOT" customer="Ferme de Gally" description="Comme beaucoup de projet d'innovation, la pérenisation de ce système de surveillance à distance impliquait de prendre en compte l'usure et la sécurisation dans la durée, sujet à part entière préalable à beaucoup d'autres" moreLink=" " />
<Card2 title="Application mobile détection activité" customer="Kering " description="L'objectif étant d'être à l'écoute des téléphones présent en magasin, ce projet fut l'occasion d'approfondir les fonctionnements bas niveau d'Android et iOS" moreLink=" " />
<Card2 title="Capteur connecté SIGFOX" customer="Docapost" description="L'utilisation d'un capteur connecté au réseau Sigfox a impliqué de préciser les questions d'autonomie et de production dans la perspective d'une première série " moreLink=" " />
<Card2 title="Application web stockage sécurisé" customer="Accedy " description="Le projet très poussé sur les questions d'encryption et de sécurité d'accès aux données a nécessité une formalisation très claire de l'architecture nécessaire pour en garantir l'intégrité, et la maîtrise des protocoles et technologies sous-jacentes" moreLink=" " />
<Card2 title="Application mobile rencontre" customer="Meet your future" description="Dans le cadre de ce projet, la réflexion sur la dimension psychologique d'une rencontre a commencé par la définition de cinq lieux de rencontres autour desquels créer l'application, illustrant la richesse de relier l'application au contexte dans lequel se trouve phyisquement l'utilisateur qui s'en sert." moreLink=" " />
<Card2 title="Plateforme mobile culture d'entreprise" customer="Total RH" description="Les sujets RH peuvent tellement bénéficier du rapport engageant et de l'instantanéité que permet une application : inversement, chaque application est l'occasion d'affirmer la relation de chacun à son travail" moreLink=" " />
<Card1 title="Site internet aquisition" customer="Crecheo" description="Ce site réalisé en 24h a été l'occasion de mélanger concrètement stratégie, technique et réalisation, et de constater à quel point ces allers retours sont fructueux." moreLink="http://crecheo.fr/" />
<Card1 title="Site internet vente en ligne" customer="Artybuzz" description="La mise en place de ce site a nécessité le retraitement d'un gros volume de données en provenance de sources externes, et leur réintégration avec différents outils de manipulation et de retraitement" moreLink="http://www.arty-buzz.fr/" />
<Card1 title="Site internet coaching" customer="Delphine Chaignon" description="C'est à l'occasion de la réalisation de ce site que j'ai travaillé sur la priorisation des informations visibles à l'écran, qui témoigne de l'importance que j'accorde à la lisibilité à l'écran" moreLink="https://www.delphinenazemi.com/" />
           </Box>
      </Container>
      </Grid>
    </Grid>
  );
}
